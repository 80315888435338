<script>
export default {
  name: "AppModal",
  props: {
    dataModal: {
      type: Object,
      default: () => {
        return {
          id: {
            type: Number,
            default: 0,
          },
          title: {
            type: String,
            default: "",
          },
          description: {
            type: String,
            default: "",
          },
          buttons: {
            type: Array,
            default: [],
          },
        };
      },
    },
  },
};
</script>

<template>
  <section>
    <div
      class="modal fade"
      id="exampleModal"
      tabindex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel">
              {{ dataModal.title }}
            </h5>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div
            class="modal-body modal-description"
            v-html="dataModal.description"
          />
          <div class="modal-footer">
            <button
              v-for="(button, index) in dataModal.buttons"
              :key="index"
              type="button"
              :class="button.classes"
              data-bs-dismiss="modal"
              @click="button.function(dataModal.id)"
            >
              {{ button.text }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
